import React from "react";

import { QuestionType } from "../../../types/Types";
import { useComputeStateContext } from "../../../contexts/ComputeStateContext";


interface PrimaryProps {
    questions : QuestionType[];
}

export default function Primary({ questions }: PrimaryProps) {
    const { computeState, computeDispatch } = useComputeStateContext();

    function handleCheck(value : string) {
        const question = computeState.questions.find((question) => question.question === value);

        // on vérifie si question.id est déjà dans primaryKeys
        if (question && question.id && question.id in computeState.primaryKeys) {
            const newPrimaryKeys = { ...computeState.primaryKeys };
            delete newPrimaryKeys[question.id];
            computeDispatch({ type: 'SET_PRIMARY_KEYS', payload: newPrimaryKeys });
        } else if (question) {
            computeDispatch({ type: 'SET_PRIMARY_KEYS', payload: { ...computeState.primaryKeys, [question.id ? question.id : -1]: value } });
        }
    }

    return (
        <div className="widget">
            <h2>Primary Keys</h2>

            {questions.map((question, index) => (
                <div key={index} className="checkbox-line">
                    <input type="checkbox" value={question.question} onChange={(e) => handleCheck(e.target.value)}/>
                    <label>{question.question}</label>
                </div>
            ))}
        </div>
    )
}
