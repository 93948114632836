import React, { useEffect, useState } from "react";

import Tab from "../../Utilities/Tab";
import { getDetailledProfessions, getFilteredJobs, getOccupationalFamilies, getSPCategories, getSPGroups } from "../../../helpers/personas";
import { JobStateType } from "../../../types/Persona";

import "../../../styles/Dashboard/Tester.css";
import JobsSelector from "./JobSelector";
import Search from "./Search";


export default function Tester() {
    const [SPGroups, setSPGroups] = useState<JobStateType>({ all: [], selected: [] });
    const [SPCategories, setSPCategories] = useState<JobStateType>({ all: [], selected: [] });
    const [OccupationalFamilies, setOccupationalFamilies] = useState<JobStateType>({ all: [], selected: [] });
    const [DetailledProfessions, setDetailledProfessions] = useState<JobStateType>({ all: [], selected: [] });


    useEffect(() => {
        getSPGroups().then((data) => setSPGroups({ all: data, selected: [] }));
        getSPCategories().then((data) => setSPCategories({ all: data, selected: [] }));
        getOccupationalFamilies().then((data) => setOccupationalFamilies({ all: data, selected: [] }));
        getDetailledProfessions().then((data) => setDetailledProfessions({ all: data, selected: [] }));
    }, []);

    useEffect(() => {
        setSPCategories({all : SPCategories.all, selected : getFilteredJobs(SPGroups, SPCategories)});
    }, [SPGroups.selected]);

    useEffect(() => {
        setOccupationalFamilies({all : OccupationalFamilies.all, selected : getFilteredJobs(SPCategories, OccupationalFamilies)});
    }
    , [SPCategories.selected]);

    useEffect(() => {
        setDetailledProfessions({all : DetailledProfessions.all, selected : getFilteredJobs(OccupationalFamilies, DetailledProfessions)});
    }, [OccupationalFamilies.selected]);

    return (
        <Tab title="Tester">
            <div className="widget">
                <h1>Tester</h1>

                <Search SPGroups={SPGroups}
                        setSPGroups={setSPGroups}
                        SPCategories={SPCategories}
                        setSPCategories={setSPCategories}
                        OccupationalFamilies={OccupationalFamilies}
                        setOccupationalFamilies={setOccupationalFamilies}
                        DetailledProfessions={DetailledProfessions}
                        setDetailledProfessions={setDetailledProfessions}
                />

                <JobsSelector jobs={SPGroups} setJobs={setSPGroups} title={{"en": "Select SP Groups", "fr": "Sélectionner des groupes SP"}}/>

                <JobsSelector jobs={SPCategories} setJobs={setSPCategories} parentJobs={SPGroups} title={{"en": "Select SP Categories", "fr": "Sélectionner des catégories SP"}}/>

                <JobsSelector jobs={OccupationalFamilies} setJobs={setOccupationalFamilies} parentJobs={SPCategories} title={{"en": "Select Occupational Families", "fr": "Sélectionner des familles professionnelles"}}/>

                <JobsSelector jobs={DetailledProfessions} setJobs={setDetailledProfessions} parentJobs={OccupationalFamilies} title={{"en": "Select Detailled Professions", "fr": "Sélectionner des professions détaillées"}}/>

            </div>
        </Tab>
    );
}
