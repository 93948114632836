import { CardData } from "../components/Utilities/Slider/Card";
import { AnswerType, GroupType } from "./Group";


export interface QuestionType {
    id?: number;
    question : string;
    type : string;
    value : number;
}

export function updateQuestionField(question: QuestionType,
                                    field: 'question' | 'type' | 'value',
                                    value: string | number) {
    if (field === 'question' && typeof value === 'string') {
        question[field] = value;
    } else if (field === 'type' && typeof value === 'string') {
        question[field] = value;
    } else if (field === 'value' && typeof value === 'number') {
        question[field] = value;
    } else {
        throw new Error(`Invalid value type for field ${field}`);
    }

    return question;
}


export interface ResponseType {
    id : number;
    question_id : number;
    answer_id : number;
    response : string;
    language : string;
}

export interface LangData {
    [key: string]: string;
}

export interface BlocProps {
    title: LangData;
    subtitle?: LangData;
    description: LangData;
}

export interface ImageType {
    link: string;
    alt: LangData;
}

export interface IllustratedType extends BlocProps {
    image: ImageType;
}

export interface DataProps {
    data : Record<string, LangData | CardData[]>
}

export enum FilterType {
    GROUP = 'group',
    QUESTION = 'question',
    ANSWER = 'answer',
}

export type FiltersType = Record<FilterType, QuestionType | GroupType | AnswerType>;

export interface BlocType {
    title: string;
    subtitle?: string;
    description: string;
}
