// Attendee.tsx

import React, { useEffect } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Cross from "../../Utilities/Icons/Cross";
import Input from "../../Utilities/Input";

import "../../../styles/Dashboard/Attendees.css";
import { AttendeeType, AttendeeWithId } from "../../../types/Attendee";

interface AttendeeProps {
    attendee: AttendeeWithId;
    setAttendee: (attendee: AttendeeWithId) => void;
    removeAttendee?: (attendeeId : string) => void;
    setValid?: (valid: boolean) => void;
}

const validationSchema : Yup.ObjectSchema<AttendeeType> = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required')
});


export default function Attendee({ attendee, setAttendee, removeAttendee, setValid }: AttendeeProps) {
    function updateValues(values : AttendeeType) {
        setAttendee({
            ...attendee,
            ...values
        });
    }

    return (
        <Formik
            initialValues={attendee}
            validationSchema={validationSchema}
            onSubmit={() => {}}
            validateOnChange={true}
        >
            {({ values, validateForm }) => {
                useEffect(() => {
                    validateForm(values).then(errors => {
                        if (Object.keys(errors).length === 0) {
                            updateValues(values);

                            if (setValid) {
                                setValid(true);
                            }
                        } else if (setValid) {
                            setValid(false);
                        }
                    });
                }, [ values, validateForm ]);

                return (
                    <Form className="attendee-container">
                        {removeAttendee && 
                            <div className="icon-container" onClick={() => removeAttendee(attendee.id)}>
                                <Cross />
                            </div>
                        }

                        <Input htmlFor="firstName" />

                        <Input htmlFor="lastName" />

                        <Input htmlFor="email" />
                    </Form>
                );
            }}
        </Formik>
    );
}
