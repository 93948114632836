// Tuto.tsx

import React, { useState, CSSProperties } from "react";
import { IllustratedType } from "../../../types/Types";
import { useLangContext } from "../../../contexts/LangContext";

import allData from "../../../data.json";


interface StepProps extends IllustratedType {
    onClick: () => void;
    selected: boolean;
}

function Step({title, description, onClick, selected}: StepProps) {
    const lang = useLangContext().lang;

    return (
        <div className={selected ? "step selected" : "step unselected"} onClick={onClick}>
            <h4>{title[lang]}</h4>    
            <p>{description[lang]}</p>
        </div>
    )
}


export default function Tuto() {
    const data = allData.Landing["tuto"];
    const lang = useLangContext().lang;

    const [ currentStep, setCurrentStep ] = useState(0);

    const steps = data.length;
    
    return (
        <div className="tuto">
            <div className="steps" style={{'--steps': steps} as CSSProperties}>
                {
                    data.map((step, index) => (
                        <Step key={index} {...step} onClick={() => setCurrentStep(index)} selected={currentStep === index}/>
                    ))
                }
            </div>
            
            <div className="illustration">
                <img src={data[currentStep].image.link} alt={(data[currentStep] as IllustratedType).image.alt[lang]}/>
            </div>
        </div>
    )
}
