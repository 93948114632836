import React, { useState } from "react";

import "../../../styles/color.css";
import { BlocType, QuestionType } from "../../../types/Types";
import FormInput from "../../Utilities/FormInput";
import Input from "../Form/Question";

import Button from "../../Utilities/Button";


interface SideProps {
    column : number;
    questions : QuestionType[];
    setQuestions : (inputs: QuestionType[]) => void;
    removeQuestion : (index: number) => void;
    addQuestion : () => void;
}

export default function Side({ column, questions, setQuestions, removeQuestion, addQuestion } : SideProps) {
    const [ info, setInfo ] = useState<BlocType>({title: "", description: ""});

    const side = column === 1 ? "left" : "right";

    return (
        <>
            <div style={{gridColumn: column, gridRow: 1}} className={`${side} foreground`}>
                <h1>Side</h1>
                <FormInput
                    label="Title"
                    value={info.title}
                    setValue={(value) => setInfo({...info, title: value})}
                />
                <FormInput
                    label="Description"
                    value={info.description}
                    setValue={(value) => setInfo({...info, description: value})}
                />

                <Button title="Add Input" onClick={addQuestion}/>
            </div>

            {
                questions.map((question, index) => (
                    <Input 
                        key={index}
                        question={question}
                        setQuestion={(question) => {
                            const updatedQuestions = [...questions];
                            updatedQuestions[index] = question;
                            setQuestions(updatedQuestions);
                        }}
                        removeQuestion={() => removeQuestion(index)}
                        style={{
                            gridColumn: column,
                            gridRow: index + 2
                        }}
                        className={`${side} foreground`}
                    />
                ))
            }

            <div
                className={`background ${side} widget`}
                style={{gridColumn : column, gridRowStart: 1, gridRowEnd : questions.length + 2}}
            />
        </>
    )
}
