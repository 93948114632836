import React from "react";

import "../../../styles/Vitrine/Pricing.css";
import "../../../styles/Vitrine/Landing.css";

import { LangData } from "../../Utilities/Props";
import { useLangContext } from "../../../contexts/LangContext";
import { PricingPlan } from "../../../types/Plan";


interface PricingCardProps {
    data : PricingPlan;
    period: string;
    priceColor?: string;
}

export default function PricingCard({ data, period, priceColor } : PricingCardProps) {
    const lang = useLangContext().lang;

    return (
        <div className="pricing-card">
            {data.title && <h3>{(data.title as LangData)[lang]}</h3>}

            {data.description && <span>{(data.description as LangData)[lang]}</span>}

            
            <div className="price-container">
                {data.price && 
                    <h4 className="price" style={priceColor ? {color: priceColor} : {}}>
                        {(data.price as LangData)[lang]}
                    </h4>
                }

                {data.monthlyPrice && period === "monthly" && 
                    <h4 className="price monthly-price" style={priceColor ? {color: priceColor} : {}}>
                        {(data.monthlyPrice as LangData)[lang]}
                    </h4>
                }

                {data.monthlyPrice && data.yearlyPrice && period === "yearly" &&
                    <>
                        <h4 className="monthly-price crossed-out">
                            {(data.monthlyPrice as LangData)[lang]}
                        </h4>

                        <h4 className="price yearly-price" style={priceColor ? {color: priceColor} : {}}>
                            {(data.yearlyPrice as LangData)[lang]}
                        </h4>
                    </>
                }

                {!data.monthlyPrice && data.yearlyPrice && period === "yearly" &&
                    <h4 className="yearly-price" style={priceColor ? {color: priceColor} : {}}>
                        {(data.yearlyPrice as LangData)[lang]}
                    </h4>
                }
            </div>

            {data.features && (data.features as LangData[]).length > 0 &&
                <ul  className="features">
                    {(data.features as LangData[]).map((feature, index) =>
                        <li key={index}>{feature[lang]}</li>
                    )}
                </ul>
            }
        </div>
    )
}
