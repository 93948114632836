import React, { useState } from "react";
import { LangData } from "./Props";
import { useLangContext } from "../../contexts/LangContext";
import "../../styles/Utilities/VerticalSelector.css";

interface VerticalSelectorProps<T extends { name: LangData }> {
    options: T[];
    selected: T[];
    setSelected: (selected: T[]) => void;
    title: LangData;
}

export default function VerticalSelector<T extends { name: LangData }>({ options, selected, setSelected, title }: VerticalSelectorProps<T>) {
    const lang = useLangContext().lang;
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option: T) => {
        if (selected.includes(option)) {
            setSelected(selected.filter((o) => o !== option));
        } else {
            setSelected([...selected, option]);
        }
    };

    const handleSelectAll = () => {
        if (selected.length === options.length) {
            setSelected([]);
        } else {
            setSelected(options);
        }
    };

    return (
        <div className="vertical-selector">
            <div className="selector-header">
                <span className="title" onClick={handleToggle}>
                    {title[lang]}
                </span>
                <button className="toggle-button" onClick={handleToggle}>
                    {isOpen ? "▲" : "▼"}
                </button>
                <button className="select-all-button" onClick={handleSelectAll}>
                    {selected.length === options.length ? "Deselect All" : "Select All"}
                </button>
            </div>

            {isOpen && (
                <div className="selector-options">
                    {options.map((option, index) => (
                        <div key={index} className="selector-option" onClick={() => handleOptionClick(option)}>
                            <input type="checkbox" checked={selected.includes(option)} readOnly />
                            <span className={selected.includes(option) ? '' : ''}>
                                {option.name[lang]}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
