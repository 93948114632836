import React, { useEffect, useState } from "react";
import Page from "../Utilities/Page";
import { useParams } from "react-router-dom";

import { get_form_by_answer_uuid } from "../../helpers/forms";
import { postAnswer } from "../../helpers/answers";
import { QuestionType } from "../../types/Types";
import Input from "./Input";
import Button from "../Utilities/Button";

import "../../styles/FillForm.css";


interface FormAttendeeType {
    title : string;
    description : string;
    questions : QuestionType[];
}


export default function FillForm() {
    const { uuid } = useParams<{ uuid: string }>();

    const [ form, setForm ] = useState<FormAttendeeType | null>(null);

    // currentIputs est un dictionnaire, où la clé est l'id de l'answer et la valeur est la valeur de l'input
    const [ currentInputs, setCurrentInputs ] = useState<Record<string, string>>({});

    useEffect(() => {
        if (uuid) {
            get_form_by_answer_uuid(uuid).then((response) => {
                const title = response.data['title'];
                const description = response.data['description'];
                const questions = response.data['questions'] as QuestionType[];

                setForm({
                    title,
                    description,
                    questions
                })
            })
        }
    }, [ uuid ]);


    function handleSubmit() {
        if (uuid) {
            postAnswer(currentInputs, uuid);
        }
    }

    return (
        <Page title="Fill Form" id="FillForm">
            {form && (
                <>
                    <div className="form-informations">
                        <h1>{form.title}</h1>
                        <p className="description">{form.description}</p>
                    </div>

                    {form.questions.map((question, index) => (
                        <Input key={index} question={question} currentQuestions={currentInputs} setCurrentQuestions={setCurrentInputs}/>
                    ))}
                </>
            )}

            <Button title={"Submit"} onClick={handleSubmit}/>
        </Page>
    )
}
