import React from "react";
import { LangData } from "./Props";
import { useLangContext } from "../../contexts/LangContext";
import Cross from "./Icons/Cross";

import "../../styles/Utilities/Tags.css";


interface TagsProps<T extends { name: LangData }> {
    tags : T[];
    setTags : (tags : T[]) => void;
    className? : string;
}

export default function Tags<T extends {name : LangData}>({ tags, setTags, className } : TagsProps<T>) { 
    const lang = useLangContext().lang;

    return (
        <div className="tags">
            {tags.map((tag, index) => (
                <div key={index} className={`tag ${className}`}>
                    <span>
                        {tag.name[lang]}
                    </span>

                    <div className="icon-container" onClick={() => setTags(tags.filter(t => t !== tag))}>
                        <Cross/>
                    </div>
                </div>
            ))}
        </div>
    )
}
