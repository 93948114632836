import React, { useState } from "react";

import { useLangContext } from "../../../contexts/LangContext";
import { BlocProps, QuestionType } from "../../../types/Types";
import InformationLogo from "../../Utilities/Logos/InformationLogo";

import "../../../styles/Input.css";

import data from "../../../content/inputs.json";


interface RangeLabelProps {
    data: BlocProps;
}

function RangeLabel({ data } : RangeLabelProps) {
    const lang = useLangContext().lang;


    const [showInfo, setShowInfo] = useState(false);

    const handleMouseEnter = () => {
        setShowInfo(true);
    };

    const handleMouseLeave = () => {
        setShowInfo(false);
    };

    const handleClick = () => {
        setShowInfo(!showInfo);
    };


    return (
        <div className="range-label-container">
            <span className="range-label">{data.title[lang]}</span>

            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
            >
                <InformationLogo/>
                {showInfo && (
                    <div className="info-popup">
                        <span>{data.title[lang]}</span>
                        {data.description && <p>{data.description[lang]}</p>}
                    </div>
                )}
            </div>
            
        </div>
    )
}


interface RangeProps {
    question : QuestionType;
    setQuestion: (inputs: QuestionType) => void;
}

export default function Range({ question, setQuestion } : RangeProps) {

    return (
        <div className="range-container">
            <RangeLabel data={data["complementary"] as BlocProps}/>

            <input
                type="range"
                min="-100"
                max="100"
                step="1"
                value={question.value}
                onChange={e => setQuestion({...question, ["value"]: parseInt(e.target.value)})}
            />

            <RangeLabel data={data["similar"] as BlocProps}/>

            <input 
                className="importance-label"
                value={question.value}
                onChange={e => setQuestion({...question, ["value"]: parseInt(e.target.value)})}
            />
        </div>
    )
}
