import React, { useEffect, useState } from "react";
import Tab from "../../Utilities/Tab";

import '../../../styles/Dashboard/Settings.css';
import { useLangContext } from "../../../contexts/LangContext";

import allData from '../../../data.json';
import settingData from '../../../content/settings.json';
import Disconnection from "../../Utilities/Disconnection";

import getNbUsers from "../../../helpers/admin";
import { UserType } from "../../../types/User";
import { LangData } from "../../Utilities/Props";
import Information from "../../Utilities/Information";
import Informations from "../../Utilities/Informations";


export default function Settings() {
    const { lang, setLang } = useLangContext();
    const langData = allData.languages;

    const [ nbUsers, setNbUsers ] = useState<number>(0);
    const [ users, setUsers ] = useState<UserType[]>([]);

    useEffect(() => {
        getNbUsers().then((data) => {
            setNbUsers(data.nb_users);
            setUsers(data.users);
        }).catch(error => {
            console.error("Erreur lors de la récupération du nombre d'utilisateurs :", error);
        });
    }, []);

    useEffect(() => {
        console.log("USERS", users);
    }, [users]);

    return (
        <Tab title="Settings">
            <div className="widget">
                <h1>{(settingData["settings"] as LangData)[lang]}</h1>
    
                <Informations>
                    {nbUsers > 0 &&
                        <Information label={settingData.usersNumber} value={nbUsers}/>
                    }

                    <Information<string>
                        label={allData.languages.title as LangData}
                        value={lang}
                        select={{
                            value: lang,
                            setValue: setLang,
                            options: langData["lang"]
                        }}
                    />
                </Informations>

                <Disconnection/>
            </div>
        </Tab>
    );
}
