// Nav.tsx

import React, { useEffect, useState } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";

import HarmateLogo from "../../Utilities/Logos/HarmateLogo";
import Burger from "../../Utilities/Icons/Burger";
import Button from "../../Utilities/Button";
import { LangData } from "../../Utilities/Props";

import { useLangContext } from "../../../contexts/LangContext";
import { useScrollContext } from "../../../contexts/ScrollContext";

import { DataProps } from "../../../types/Types";


export default function Nav({ data } : DataProps) {
    const lang = useLangContext().lang;
    const navigate = useNavigate();

    const [ scale, setScale ] = useState(0);
    const { scroll, setScroll } = useScrollContext();

    const headerHeight = scale * 8;
    const [ opacity, setOpacity ] = useState(0);

    const [ burgerExpanded, setBurgerExpanded ] = useState(false);

    const maxOpacity = 0.9;

    const isLargeScreen = useMediaQuery('(min-width : 800px), (orientation : landscape)');


    useEffect(() => {
        function handleResize() {
            setScale((window.innerWidth + window.innerHeight) * 0.5 * 0.01);
        }

        // Calcul initial
        handleResize();

        // Ajouter un écouteur d'événement pour recalculer lors du redimensionnement de la fenêtre
        window.addEventListener('resize', handleResize);

        // Nettoyage de l'écouteur d'événement lors du démontage du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [ window.innerHeight, window.innerWidth ]);

    useEffect(() => {
        if(scroll && scroll > headerHeight) {
            setOpacity(maxOpacity);
        } else if (scroll && scroll > 0) {
            setOpacity((scroll / headerHeight) * maxOpacity);
        } else {
            setOpacity(0);
        }
    }, [ scroll ]);


    function handleClick() {
        setScroll(0);
        setBurgerExpanded(false);
    }

    function handleRootClick() {
        handleClick();
        navigate('/');
    }

    function handleBurgerClick() {
        setBurgerExpanded(!burgerExpanded);
    }


    return(
        <nav style={{
            backgroundColor : `rgba(255, 255, 255, ${opacity})`,
            borderBottom: `solid calc(0.1 * var(--scale)) rgba(206, 206, 206, ${opacity})`}}
            
            className={`${burgerExpanded ? 'expanded' : ''}`}
        >   
            <div className="permanent">
                <div onClick={handleRootClick} className="harmate-logo-container">
                    <HarmateLogo/>
                    <span>Harmate</span>
                </div>

                <div className="buttons">
                    {isLargeScreen &&
                        <>
                            <Button
                                to="/blog"
                                title={(data['blog'] as LangData)[lang]}
                                onClick={() => setScroll(0)}
                                className="nav-button"
                            />
                            <Button
                                to="/whatsNew"
                                title={(data['whatsNew'] as LangData)[lang]}
                                onClick={() => setScroll(0)}
                                className="nav-button"
                            />
                            <Button
                                to="/pricing"
                                title={(data['pricing'] as LangData)[lang]}
                                onClick={() => setScroll(0)}
                                className="nav-button"
                            />
                            <Button
                                to="/signUp"
                                title={(data['signUp'] as LangData)[lang]}
                            />
                        </>
                    }

                    <Button
                        to="/signIn"
                        title={(data['signIn'] as LangData)[lang]}
                        className="signin"
                    />

                    {!isLargeScreen &&
                        <div className="burger-container" onClick={handleBurgerClick}>
                            <Burger/>
                        </div>
                    }
                </div>
            </div>

            {!isLargeScreen && burgerExpanded &&
                <div className="burger-menu">
                    <Button
                        to="/blog"
                        title={(data['blog'] as LangData)[lang]}
                        onClick={handleClick}
                        className="burger-button"
                    />
                    <Button
                        to="/whatsNew"
                        title={(data['whatsNew'] as LangData)[lang]}
                        onClick={handleClick}
                        className="burger-button"
                    />
                    <Button
                        to="/pricing"
                        title={(data['pricing'] as LangData)[lang]}
                        onClick={handleClick}
                        className="burger-button"
                    />
                    <Button
                        to="/signUp"
                        title={(data['signUp'] as LangData)[lang]}
                        onClick={handleClick}
                        className="burger-button"
                    />
                    <Button
                        to="/signIn"
                        title={(data['signIn'] as LangData)[lang]}
                        onClick={handleClick}
                        className="burger-button"
                    />
                </div>
            }
        </nav>
    )
}
