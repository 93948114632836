import axios from "axios";
import { getApiConfig } from "./utils";
import { DetailledProfession, OccupationalFamily, SPCategory, SPGroup, JobStateType, JobType } from "../types/Persona";


export async function getSPGroups() {
    const config = getApiConfig({ endpoint: 'getSPGroups' });

    const response = await axios.get(config.backendUrl, {
        headers: {
            Authorization: config.token
        }
    });

    return response.data['SPGroups'] as SPGroup[];
}


export async function getSPCategories() {
    const config = getApiConfig({ endpoint: 'getSPCategories' });

    const response = await axios.get(config.backendUrl, {
        headers: {
            Authorization: config.token
        }
    });

    return response.data['SPCategories'].map((data: JobType) => new SPCategory(data.id, data.name, data.PCS2020Code, new SPGroup(data.id, data.name, data.PCS2020Code, data.shortName), data.shortName));
}


export async function getOccupationalFamilies() {
    const config = getApiConfig({ endpoint: 'getOccupationalFamilies' });

    const response = await axios.get(config.backendUrl, {
        headers: {
            Authorization: config.token
        }
    });

    return response.data['OccupationalFamilies'].map((data: JobType) => new OccupationalFamily(data.id, data.name, data.PCS2020Code, new SPCategory(data.id, data.name, data.PCS2020Code, new SPGroup(data.id, data.name, data.PCS2020Code, data.shortName), data.shortName), data.shortName));
}


export async function getDetailledProfessions() {
    const config = getApiConfig({ endpoint: 'getDetailledProfessions' });

    const response = await axios.get(config.backendUrl, {
        headers: {
            Authorization: config.token
        }
    });

    return response.data['DetailledProfessions'].map((data: JobType) => new DetailledProfession(data.id, data.name, data.PCS2020Code, new OccupationalFamily(data.id, data.name, data.PCS2020Code, new SPCategory(data.id, data.name, data.PCS2020Code, new SPGroup(data.id, data.name, data.PCS2020Code, data.shortName), data.shortName), data.shortName), data.shortName));
}


export function getFilteredJobs(parentJobs: JobStateType, jobs: JobStateType) : JobType[] {
    return jobs.selected.filter(category =>
        parentJobs.selected.some(group => category.parent && group.id === category.parent.id)
    )
}

