import React from "react";

import { QuestionType } from "../../types/Types";


interface InputProps {
    question : QuestionType;
    setCurrentQuestions : React.Dispatch<React.SetStateAction<Record<string, string>>>;
    currentQuestions : Record<string, string>;
}


export default function Input({ question, setCurrentQuestions, currentQuestions } : InputProps) {

    function updateQuestion(e: React.ChangeEvent<HTMLInputElement>) {
        const updatedQuestions = { ...currentQuestions };
        if (question.id) {
            updatedQuestions[question.id] = e.target.value;
        }
        setCurrentQuestions(updatedQuestions);
    }

    return (
        <div className="input">
            <div className="head">
                <span style={{background:`radial-gradient(circle at center, red, blue ${(question.value + 100) / 2}%)`}}>
                    {question.value}
                </span>
                <label>{question.question}</label>
            </div>
            
            <input
                type={question.type}
                placeholder="Answer"
                onChange={updateQuestion}
                value={(question.id ? currentQuestions[question.id] : '' ) || ''}
            />
        </div>
    );
}
