// List.tsx

import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';

import "../../../styles/Vitrine/Blog.css";
import "../../../styles/Vitrine/Landing.css";

import Card from "../../Utilities/Card";
import Footer from '../../Footer/Footer';

import { getFrontendUrl, useScroll } from '../../../helpers/utils';
import { getArticlesAbstracts } from '../../../helpers/blog';

import { ArticleType } from '../../../types/Blog';
import { useLangContext } from '../../../contexts/LangContext';

import allData from "../../../data.json";
import { LangData } from '../../Utilities/Props';


export default function List() {
    const [articles, setArticles] = useState<ArticleType[]>([]);

    const lang = useLangContext().lang;

    useEffect(() => {
        getArticlesAbstracts().then((articles) => {
            setArticles(articles);
        });
    }, []);

    const data = allData["blog"];
    useScroll("Blog");
    const navigate = useNavigate();

    return (
        <div id="Blog" className="vitrine-page">
            <Helmet>
                <title>{(data['SEOTitle'] as LangData)[lang]}</title>
                <meta name="description" content={(data["SEODescription"] as LangData)[lang]}/>
                <link rel="canonical" href={getFrontendUrl("whatsNew")}/>
            </Helmet>

            <div className="hero">
                <h1>{(data['title'] as LangData)[lang]}</h1>
                <h2>{(data['subtitle'] as LangData)[lang]}</h2>
            </div>

            {
                articles.map((article, index) => {
                    return <Card key={index} data={article} onClick={() => navigate(`/blog/${article.link[lang]}`)}/>
                })
            }

            <Footer/>
        </div>
    )
}
