// GenerateQuestionButton.tsx

import React, { useEffect, useState } from "react";

import { QuestionType } from "../../../types/Types";
import { FormType } from "../../../types/Form";
import { LangData } from "../../Utilities/Props";
import { useLangContext } from "../../../contexts/LangContext";
import AIWriterLogo from "../../Utilities/Logos/AIWriterLogo";
import Button from "../../Utilities/Button";
import { generateQuestion } from "../../../helpers/forms";

import '../../../styles/Dashboard/Form.css';


interface GenerateQuestionButtonProps {
    form : FormType;
    questions : QuestionType[];
    setQuestions : (questions : QuestionType[]) => void;
    data: Record<string, LangData>;
}
 
export default function GenerateQuestionButton({ form, questions, setQuestions, data }: GenerateQuestionButtonProps) {
    const lang = useLangContext().lang;

    const [deactivate, setDeactivate] = useState(true);

    const [showInfo, setShowInfo] = useState(false);

    const handleMouseEnter = () => {
        if (deactivate) {
            setShowInfo(true);
        }
    };

    const handleMouseLeave = () => {
        setShowInfo(false);
    };

    useEffect(() => {
        if (form.title == undefined || form.title == "") {
            setDeactivate(true);
        } else {
            setDeactivate(false);
        }

    }, [form, questions]);

    function handleGenerateQuestion() {
        generateQuestion(form, questions).then((newQuestion) => {
            setQuestions([...questions, { question : newQuestion, value : 0, type : 'text' } as QuestionType]);
        });
    }

    return (
        <>
            <Button
                onClick={handleGenerateQuestion} 
                title={data['button'][lang]}
                leftIcon={<AIWriterLogo/>}
                className={deactivate ? "deactivate" : ""}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />

            {showInfo && (
                <div className="info-popup">
                    <span>{data.requirement[lang]}</span>
                    {/* <p>{data["description"][lang]}</p> */}
                </div>
            )}
        </>
    )
}
