// Landing.tsx

import React from "react";

import { BlocProps, LangData } from "../../Utilities/Props";
import Slider from "../../Utilities/Slider/Slider";
import { CardData } from "../../Utilities/Slider/Card";

import "../../../styles/Vitrine/Landing.css";

import allData from "../../../data.json";
import landingData from "../../../content/landing.json";

import { useLangContext } from "../../../contexts/LangContext";

import Footer from "../../Footer/Footer";

import Hero from "./Hero";
import UseCases from "./UseCases";
import Pricing from "../Pricing/Pricing";
import Personae from "./Personae";
import { Helmet } from "react-helmet-async";
import { getFrontendUrl, useScroll } from "../../../helpers/utils";
import Partnerships from "./Partnerships";
import Tuto from "./Tuto";


export default function Landing() {
    const data : Record<string, LangData | CardData[]> = allData["Landing"];
    const useCasesData : Record<string, LangData | CardData[] | BlocProps[]> = allData["useCases"];

    const lang = useLangContext().lang;

    useScroll("Landing");

    return (
        <div id="Landing" className="vitrine-page">
            <Helmet>
                <title>{(data["SEOTitle"] as LangData)[lang]}</title>
                <meta name="description" content={(data["SEODescription"] as LangData)[lang]}/>
                <link rel="canonical" href={getFrontendUrl("")}/>
            </Helmet>

            <Hero data={data as Record<string, LangData>}/>

            <Tuto/>

            <h2>{(useCasesData['title'] as LangData)[lang]}</h2>
            <UseCases data={(useCasesData["briefs"]) as BlocProps[]}/>
            <Slider data={(useCasesData["cards"] as CardData[])} className="use-cases-slider"/>

            <Personae data={landingData["asATrainer"]}/>
            <Personae data={landingData["asARecruiter"]}/>
            <Personae data={landingData["asAnEventOrganizer"]}/>
            <Personae data={landingData["asAMarketer"]}/>

            <Slider data={(data["slider"] as CardData[])}/>

            <Pricing/>

            <Partnerships data={allData["partnerships"]}/>

            <Footer/>
        </div>
    );
}
