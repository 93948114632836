import React, { useState } from "react";

import { JobType, JobStateType, isSPGroup, isSPCategory, isOccupationalFamily, isDetailledProfession } from "../../../types/Persona";


interface SearchProps {
    SPGroups: JobStateType;
    setSPGroups: (jobs: JobStateType) => void;
    SPCategories: JobStateType;
    setSPCategories: (jobs: JobStateType) => void;
    OccupationalFamilies: JobStateType;
    setOccupationalFamilies: (jobs: JobStateType) => void;
    DetailledProfessions: JobStateType;
    setDetailledProfessions: (jobs: JobStateType) => void;
}

export default function Search({ SPGroups,
                                 setSPGroups,
                                 SPCategories,
                                 setSPCategories,
                                 OccupationalFamilies,
                                 setOccupationalFamilies,
                                 DetailledProfessions,
                                 setDetailledProfessions }: SearchProps) {
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearchFocused, setIsSearchFocused] = useState(false);

    const filteredJobs = [
        ...SPGroups.all,
        ...SPCategories.all,
        ...OccupationalFamilies.all,
        ...DetailledProfessions.all
    ].filter(job => job.name.en.toLowerCase().includes(searchTerm.toLowerCase()) || job.name.fr.toLowerCase().includes(searchTerm.toLowerCase()));

    
    const handleAddJob = (job: JobType) => {
        if (isSPGroup(job)) {
            if (!SPGroups.selected.includes(job)) {
                setSPGroups({all : SPGroups.all, selected : [...SPGroups.selected, job]});
            }
        } else if (isSPCategory(job)) {
            if (!SPCategories.selected.includes(job)) {
                setSPCategories({all : SPCategories.all, selected : [...SPCategories.selected, job]});
            }
        } else if (isOccupationalFamily(job)) {
            if (!OccupationalFamilies.selected.includes(job)) {
                setOccupationalFamilies({all : OccupationalFamilies.all, selected : [...OccupationalFamilies.selected, job]});
            }
        } else if (isDetailledProfession(job)) {
            if (!DetailledProfessions.selected.includes(job)) {
                setDetailledProfessions({all : DetailledProfessions.all, selected : [...DetailledProfessions.selected, job]});
            }
        }
    };


    return (
        <div>
            <input
                type="text"
                placeholder="Search for jobs..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => {
                    setIsSearchFocused(false);
                    setSearchTerm(""); // Réinitialiser la barre de recherche
                }}
            />

            {isSearchFocused && filteredJobs.length > 0 && (
                <div className="search-results">
                    {filteredJobs.map((job, index) => (
                        <div key={index} className="search-result">
                            {job.name.en} / {job.name.fr}
                            <button onClick={() => handleAddJob(job)}>Add</button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
