import React, { useEffect, useState } from "react";

import VerticalSelector from "../../Utilities/VerticalSelector";
import { JobType, JobStateType } from "../../../types/Persona";
import { LangData } from "../../Utilities/Props";
import Tags from "../../Utilities/Tags";
import { getFilteredJobs } from "../../../helpers/personas";


interface Props {
    jobs: JobStateType;
    setJobs: (jobs: JobStateType) => void;
    parentJobs?: JobStateType;
    title: LangData;
}


export default function JobsSelector({ jobs, setJobs, parentJobs, title } : Props) {
    const [options, setOptions] = useState<JobType[]>([]);

    useEffect(() => {
        if (parentJobs) {
            setOptions(getFilteredJobs(parentJobs, jobs));
        } else {
            setOptions(jobs.all);
        }
        
    }, [jobs.all, parentJobs?.selected]);


    return (
        <>
            { jobs.all.length > 0 &&
            <>
                <VerticalSelector
                    options = {options}
                    selected = {jobs.selected}
                    setSelected = {(selected) => setJobs({ all: jobs.all, selected: selected })}
                    title = {title}
                />

                {jobs.selected.length > 0 &&
                    <Tags tags={jobs.selected} setTags={(tags) => setJobs({ all: jobs.all, selected: tags })}/>
                }
            </>
            }
        </>
    )
}
