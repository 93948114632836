import React from "react";

import { useLangContext } from "../../../contexts/LangContext";
import { LangData } from "../../Utilities/Props";


interface PartnershipsProps {
    data: Record<string, LangData | string | Record<string, string>[]>;
}

export default function Partnerships({ data }: PartnershipsProps) {
    const lang = useLangContext().lang;

    function handleClick(link : string) {
        window.open((link as string), "_blank");
    }

    return (
        <div className="partnerships">
            <h2>{(data.title as LangData)[lang]}</h2>
            
            <div className="partnerships-slider">
                {
                    (data["partners"] as Record<string, string>[]).map((partner, index) => (
                        <img key={index} src={partner.image} alt={partner.name} onClick={() => handleClick(partner.link)}/>
                    ))
                }
            </div>
        </div>
    )
}
