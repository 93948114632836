import React from "react";

import { Helmet } from "react-helmet-async";

import Pricing from "./Pricing";

import allData from "../../../data.json";
import { useLangContext } from "../../../contexts/LangContext";
import { LangData } from "../../../types/Types";
import { getFrontendUrl, useScroll } from "../../../helpers/utils";
import Footer from "../../Footer/Footer";

import "../../../styles/Vitrine/Pricing.css";
import "../../../styles/Vitrine/Landing.css";


export default function PricingPage() {
    const lang = useLangContext().lang;

    const data = allData["pricing"];

    useScroll("Pricing");

    return(
        <div id="Pricing" className="vitrine-page">
            <Helmet>
                <title>{(data['SEOTitle'] as LangData)[lang]}</title>
                <meta name="description" content={(data["SEODescription"] as LangData)[lang]}/>
                <link rel="canonical" href={getFrontendUrl("whatsNew")}/>
            </Helmet>

            <h1>{(data['title'] as LangData)[lang]}</h1>
            <h2>{(data['subtitle'] as LangData)[lang]}</h2>

            <Pricing/>

            <Footer/>
        </div>
    )
}
