// Questions.tsx

import React, { useEffect, useState } from "react";
import { QuestionType } from "../../../types/Types";
import Question from "./Question";

import '../../../styles/Dashboard/Form.css';

import data from "../../../content/inputs.json";
import { useLangContext } from "../../../contexts/LangContext";
import { LangData } from "../../Utilities/Props";
import GenerateQuestionButton from "./GenerateQuestionButton";
import Button from "../../Utilities/Button";
import { FormType } from "../../../types/Form";
import Information from "../../Utilities/Information";
import Informations from "../../Utilities/Informations";
import { isLLMAvailable } from "../../../helpers/forms";


interface QuestionsProps {
    questions : QuestionType[];
    setQuestions : (inputs: QuestionType[]) => void;
    controls? : boolean;
    form? : FormType;
}

export default function Questions({ questions, setQuestions, controls = false, form }: QuestionsProps) {
    const lang = useLangContext().lang;

    const [ LLMAvailable, setLLMAvailable ] = useState<boolean>(false);

    useEffect(() => {
        isLLMAvailable().then((available) => {
            setLLMAvailable(available);
        });
    }, [ questions ]);

    function addQuestion() {
        setQuestions([...questions, { question : '', value : 0, type : 'text' }]);
    }

    return (
        <div className="widget questions">
            <Informations>
                <Information<string> label={data["questionsNumber"] as LangData} value={questions.length}/>
            </Informations>

            {controls &&
                <div className='buttons'>
                    <Button onClick={addQuestion} title={(data["newInput"] as LangData)[lang]}/>
                    {form && LLMAvailable &&
                        <GenerateQuestionButton form={form} questions={questions} setQuestions={setQuestions} data={data["generateQuestion"]}/>
                    }
                </div>
            }

            <div className='inputs'>
                {questions.map((question, index) => (
                    <Question
                        key={index}
                        question={question}
                        setQuestion={(question) => {
                            const updatedQuestions = [...questions];
                            updatedQuestions[index] = question;
                            setQuestions(updatedQuestions);
                        }}
                        removeQuestion={() => {
                            const updatedQuestions = [...questions];
                            updatedQuestions.splice(index, 1);
                            setQuestions(updatedQuestions);
                        }}
                    />
                ))}
            </div>
        </div>
    )
}
