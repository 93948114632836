import { LangData } from "./Types";

export type JobType = SPGroup | SPCategory | OccupationalFamily | DetailledProfession;

export interface JobStateType {
    all: JobType[];
    selected: JobType[];
}

abstract class Job {
    id: number;
    name: LangData;
    PCS2020Code: string;
    shortName?: LangData;
    parent?: JobType | null;

    constructor(id: number, name: LangData, PCS2020Code: string, shortName?: LangData) {
        this.id = id;
        this.name = name;
        this.PCS2020Code = PCS2020Code;
        this.shortName = shortName;
    }
}


export class SPGroup extends Job {
    constructor(id: number, name: LangData, PCS2020Code: string, shortName?: LangData) {
        super(id, name, PCS2020Code, shortName);
    }
}


export class SPCategory extends Job {
    parent: SPGroup;

    constructor(id: number, name: LangData, PCS2020Code: string, SPGroup: SPGroup, shortName?: LangData) {
        super(id, name, PCS2020Code, shortName);
        this.parent = SPGroup;
    }
}

export class OccupationalFamily extends Job {
    parent: SPCategory;

    constructor(id: number, name: LangData, PCS2020Code: string, SPCategory: SPCategory, shortName?: LangData) {
        super(id, name, PCS2020Code, shortName);
        this.parent = SPCategory;
    }
}

export class DetailledProfession extends Job {
    parent: OccupationalFamily;

    constructor(id: number, name: LangData, PCS2020Code: string, OccupationalFamily: OccupationalFamily, shortName?: LangData) {
        super(id, name, PCS2020Code, shortName);
        this.parent = OccupationalFamily;
    }
}


export function isSPGroup(job: JobType): job is SPGroup {
    return job instanceof SPGroup;
}

export function isSPCategory(job: JobType): job is SPCategory {
    return job instanceof SPCategory;
}

export function isOccupationalFamily(job: JobType): job is OccupationalFamily {
    return job instanceof OccupationalFamily;
}

export function isDetailledProfession(job: JobType): job is DetailledProfession {
    return job instanceof DetailledProfession;
}
