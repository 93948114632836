// Question.tsx

import React from "react";
import TextareaAutosize from 'react-textarea-autosize';

import Cross from "../../Utilities/Icons/Cross";
import { QuestionType, updateQuestionField } from "../../../types/Types";

import "../../../styles/Input.css";
import { useLangContext } from "../../../contexts/LangContext";
import { LangData } from "../../Utilities/Props";
import Range from "./Range";


import data from "../../../content/inputs.json";


interface QuestionProps {
    question : QuestionType;
    setQuestion : (questions: QuestionType) => void;
    removeQuestion? : () => void;
    style? : React.CSSProperties;
    className? : string;
}

export default function Question({ question, setQuestion, removeQuestion, style, className }: QuestionProps) {
    const lang = useLangContext().lang;

    return (
        <div className={className ? `input-container ${className}` : "input-container"} style={style}>
            {removeQuestion &&
                <div className="icon-container" onClick={removeQuestion}>
                    <Cross/>
                </div>
            }

            <div className="input-entries">
                <TextareaAutosize
                    className="title-input"
                    placeholder={(data['newInput'] as LangData)[lang]}
                    value={question.question}
                    onChange={e => setQuestion(updateQuestionField(question, "question", e.target.value))}
                    minRows={1}
                />

                <div className="settings">
                    <Range question={question} setQuestion={setQuestion}/>

                    <select
                        value={question.type}
                        onChange={e => updateQuestionField(question, "type", e.target.value)}
                        className="custom-select"
                    >
                        <option value="text">{(data['text'] as LangData)[lang]}</option>
                        <option value="number">{(data['number'] as LangData)[lang]}</option>
                        <option value="date">{(data['date'] as LangData)[lang]}</option>
                        <option value="email">{(data['email'] as LangData)[lang]}</option>
                        <option value="tel">{(data['tel'] as LangData)[lang]}</option>
                        <option value="url">{(data['url'] as LangData)[lang]}</option>
                    </select>
                </div>

            </div>
        </div>
    )
}
