// WhatsNew.tsx

import React from "react";
import { Helmet } from "react-helmet-async";

import "../../../styles/Vitrine/WhatsNew.css";
import "../../../styles/Vitrine/Landing.css";

import whatsNewData from "../../../content/whatsNew.json";
import Card from "../../Utilities/Card";
import { getFrontendUrl, useScroll } from "../../../helpers/utils";
import Footer from "../../Footer/Footer";


import allData from "../../../data.json";
import { useLangContext } from "../../../contexts/LangContext";
import { LangData } from "../../Utilities/Props";


export default function WhatsNew() {
    const lang = useLangContext().lang;
    const data = allData["whatsNew"];
    useScroll("WhatsNew");

    return (
        <div id="WhatsNew" className="vitrine-page">
            <Helmet>
                <title>{(data['SEOTitle'] as LangData)[lang]}</title>
                <meta name="description" content={(data["SEODescription"] as LangData)[lang]}/>
                <link rel="canonical" href={getFrontendUrl("whatsNew")}/>
            </Helmet>
            
            <div className="hero">
                <h1>{(data['title'] as LangData)[lang]}</h1>
                <h2>{(data['subtitle'] as LangData)[lang]}</h2>
            </div>

            {
                whatsNewData.map((data, index) => (
                    <Card key={index} data={data}/>
                ))
            }

            <Footer/>

        </div>
    );
}
