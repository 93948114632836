import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Tab from '../../Utilities/Tab';
import Button from '../../Utilities/Button';
import Arrow from '../../Utilities/Icons/Arrow';
import { LangData } from '../../Utilities/Props';

import '../../../styles/Dashboard/Form.css';
import '../../../styles/Dashboard/Dashboard.css';

import HeaderForm from './HeaderForm';
import Questions from './Questions';

import { QuestionType } from '../../../types/Types';
import { FormType } from '../../../types/Form';

import { submitForm, updateForms } from '../../../helpers/forms';

import { useLangContext } from '../../../contexts/LangContext';

import allData from '../../../data.json';
import { useFormsContext } from '../../../contexts/FormsContext';


export default function Home() {
    const data = allData['Form'];
    const lang = useLangContext().lang;

    const navigate = useNavigate();
    const setForms = useFormsContext().setForms;

    const [ questions, setQuestions ] = useState<QuestionType[]>([]);
    const [ form, setForm ] = useState<FormType>({} as FormType);

    async function saveForm() {
        const submitedForm = await submitForm({form, questions});

        updateForms(navigate, setForms);

        return submitedForm;
    }

    function handleClick() {
        saveForm().then((submitedForm) => {
            if (submitedForm) {
                navigate(`/dashboard/attendees/${submitedForm.id}`);
            }
        });
    }

    function handleQRCode() {
        saveForm().then((submitedForm) => {
            if (submitedForm && submitedForm.id) {
                navigate(`/dashboard/results/${submitedForm.id}`);
            }
        });
    }

    return (
        <Tab title="Form">

            <HeaderForm form={form} setForm={setForm} data={data["header"]}/>

            <Questions questions={questions} setQuestions={setQuestions} controls={true} form={form}/>
            
            <div className='widget buttons'>
                <Button title={(data["saveForm"] as LangData)[lang]} onClick={saveForm}/>

                <Button title="QRCode" onClick={handleQRCode}/>
                
                <Button title={(data["addAttendees"] as LangData)[lang]} onClick={handleClick} rightIcon={<Arrow/>}/>
            </div>
        </Tab>
    );
}
